import React from "react";
import logo from "../assets/logo.svg";

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#ebf7ec] via-[#f0f9f1] to-[#e6f6e8] flex flex-col justify-center items-center p-6">
      <div className="mb-10 w-32 h-32 text-[#39b54a]">
        <img src={logo} alt="Aspyn Logo" className="w-full h-full" />
      </div>

      <h1 className="text-6xl font-bold mb-5 text-center text-[#2d8e3a] tracking-tight">
        Aspyn
      </h1>

      <p className="text-xl font-light max-w-3xl text-center mb-10 text-gray-500">
        The architecture you wished you had from day one.
      </p>

      <div className="text-sm font-medium uppercase tracking-wider bg-gray-500 text-white px-8 py-3 rounded-full shadow-sm">
        Coming Soon
      </div>

      <div className="absolute bottom-6 text-gray-500 text-sm">
        © {new Date().getFullYear()} Aspyn
      </div>
    </div>
  );
};

export default LandingPage;
